import React, { useState, useEffect } from 'react';
import { get } from '../fetch.js';
import { Toggle } from '../helpers.js';
import C from '../config.js'

function Debug() {
    const [data, setData] = useState([]);
    const [gridView, setGridView] = useState(false)

    useEffect(() => {
        getStatus();
        setInterval(getStatus, C.monitoringDelay);
    }, [])

    async function getStatus() {
        const projectList = await get('monitoring', 'GET', null, 'google');
        projectList.data.timestamp = new Date().toLocaleTimeString();
        setData(projectList.data);
    }

    function renderMonitoringTable() {
        if (data && data.data) {
            const allTableHeaders = Object.keys(data?.data?.all);
            const tableValues = Object.keys(data?.data?.byService);
            const tableHeaders = Object.keys(data?.data?.byService[tableValues[0]])
            const tableGridHeaders = Object.keys(data?.data?.byService[tableValues[0]]).filter(a => a !== 'hosts' && a !== 'name')
            const status = data.status;

            function renderSubArr(val) {
                if (Array.isArray(val)) {
                    if (val.length == 0) return 0;
                    return <ul>
                        {val.map(v => <li key={v}>{v}</li>)}
                    </ul>
                }
                return val;
            }

            function styleChecker(val) {
                return val == 'ok' ? 'greenyellow' : 'inherit';
            }

            return (
                <div>
                    <div className={`alert alert-${status == 'ok' ? 'success' : 'danger'}`} role="alert">Overall Status <b>{status == 'ok' ? 'OK' : 'ERROR'}</b></div>
                    <blockquote><i>Service updates automatically every {C.monitoringDelay / 1000} seconds</i></blockquote>
                    <div style={{ float: 'right', margin: '1%' }}>
                        <span >Last sync: {data.timestamp}</span>
                    </div>
                    <h5 style={{ margin: '20px 0' }}>All</h5>
                    <table className='table tbl table-striped table-bordered'>
                        <thead><tr>{allTableHeaders.map((rows, index) => <th className='header table-warning' key={index}>{rows.replaceAll('_', ' ')}</th>)}</tr></thead>
                        <tbody><tr>{allTableHeaders.map((val, i) => <td key={i} style={{ background: styleChecker(data?.data?.all[val]) }}>{data?.data?.all[val]}</td>)}</tr></tbody>
                    </table>
                    <h5 style={{ margin: '20px 0' }}>By Service</h5>
                    <Toggle
                        title="grid view"
                        onChange={() => setGridView(!gridView)}
                    />
                    {gridView ? <div className='container' style={{maxWidth: '80%'}}>{tableValues.map(v => (<div className="card" style={{width: '18rem' , margin: '1%', display: 'inline-block', textAlign: 'center', background: styleChecker(data?.data?.byService[v].status)}}>
                            <i className="bi bi-hdd-network-fill"/>
                            <div className="card-body">
                                <h5 className="card-title">{data?.data?.byService[v].name}</h5>
                                <ul>{tableGridHeaders.map(th => <li><b>{th}: </b>{renderSubArr(data?.data?.byService[v][th])}</li>)}</ul>
                                {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                    </div>))}</div> : <table className='table tbl table-striped table-bordered'>
                        <thead><tr>{tableHeaders.map((rows, index) => <th className='header table-warning' key={index}>{rows.replaceAll('_', ' ')}</th>)}</tr></thead>
                        <tbody>{tableValues.map((value, index) => <tr key={index}>{tableHeaders.map((val, i) => <td key={i} style={{ background: styleChecker(data?.data?.byService[value][val]) }}>{renderSubArr(data?.data?.byService[value][val])}</td>)}</tr>)}</tbody>
                    </table>}

                </div>
            )
        }

    }
    return (
        <div className="container-fluid">
            {renderMonitoringTable()}
        </div>
    )


}

export default Debug;