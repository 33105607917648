import React, { useEffect, useState } from "react";
import { requestWithAuth, post } from "../fetch.js";
import { AuthChecker, isSupplyConnected } from '../auth.js';
import { useCSVDownloader, usePapaParse } from 'react-papaparse';
import Loader from './Loader.js'

const suggestions = [
    'Give me the list of apps for account Lite Games GmbH',
    'Generate a reporting statistics report of last week for account TorAlarm GmbH grouped by App',
]

export default function Form() {
    const [isLoading, setIsLoading] = useState(false);
    const [inputText, setInputText] = useState("");
    const [aiResponse, setAiResponse] = useState("");
    const [history, setHistory] = useState([]);
    const [supplyConnected, setSupplyConnected] = useState(false)
    const [lock, setLock] = useState(window.unlocked || true)

    const { CSVDownloader, Type } = useCSVDownloader();
    const { jsonToCSV } = usePapaParse();

    async function fetchAccounts() {
        setIsLoading(true)
        await post('reports/dashboardStats', { query: '/accounts' });
        setIsLoading(false)
    }

    // Scroll to bottom when messages change
    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 100000), 1)
    }, [history]);

    useEffect(() => {
        if (aiResponse !== '') setHistory([...history, aiResponse])
    }, [aiResponse])

    useEffect(() => {
        let aux = isSupplyConnected()
        setSupplyConnected(aux)
        if (aux) fetchAccounts()
    }, [])

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    async function processInput(forcedEntry) {
        const message = (forcedEntry) ? forcedEntry : inputText;
        setIsLoading(true)
        // first create a thread
        const threadResponse = await requestWithAuth('ai', 'POST', { message }, 'all');
        if (threadResponse) {
            let res = threadResponse.message;
            setAiResponse(res);
        } else {
            setAiResponse('Unexpected Error processing response');
        }
        setIsLoading(false)
    }

    const handleButtonClick = async () => {
        setHistory([...history, inputText])
        await processInput();
        setInputText('')
    };

    function isCSV(res) {
        let render;
        if (!res?.type) return <pre>{res}</pre>
        switch (res.type) {
            case 'object':
                const csvString = jsonToCSV(res.result);
                render = (<div>
                    <a style={{ margin: '1%' }} type="button" target="_blank" className="btn btn-light" href={`data:text/json;charset=utf-8,${encodeURIComponent(res.result)}`} download={res.query}>JSON</a>
                    <CSVDownloader data={csvString} className="btn btn-light" filename={res.query} type={Type.CSV}>CSV</CSVDownloader>
                </div>)
                break;
            case 'csv':
                render = (<div>
                    <CSVDownloader data={res.result} className="btn btn-light" filename={res.query} type={Type.CSV}>CSV</CSVDownloader>
                </div>)
                break;
            case 'chat':
                render = (<pre>{res.result}</pre>);
                break;
        }
        return render;
    }

    const setSuggestion = async (entry) => {
        await setInputText(entry);
        await setHistory([...history, entry])
        await processInput(entry);
        setInputText('')
    }

    return (
        <div className="container-fluid gravvy">
            {!supplyConnected && AuthChecker('supply')}

            <div className="flex-grow-1" style={{ marginBottom: '2%' }}>
                {history.map((h, i) => (<div className="chatReply" key={h + i} style={{ float: i % 2 === 0 ? 'right' : 'left' }}>
                    {i % 2 === 1 && <i className="'bi bi-robot" />}
                    {isCSV(h)}
                </div>))}
            </div>

            {lock ? (
                <div style={{padding: '1%', background: 'aliceblue', display: 'ruby'}}>
                    <i className="bi bi-shield-lock"></i>
                    <input
                        className="form-control"
                        type="text"
                        style={{ width: '20%' }}
                        placeholder="Auth code"
                        onChange={(e) => {
                            if (e.target.value == 'Alsterufer4') setLock(false);
                        }}
                    />
                </div>
            ) :
                isLoading ? <div><Loader /></div> : (<div className="chatBox input-group p-3 border-top">
                    <div style={{ display: 'grid', margin: '1%', color: 'white' }}>
                        Suggestions:
                        {suggestions.map(s => <a className="link-opacity-10" href="#" key={s} style={{ color: 'white' }} onClick={() => setSuggestion(s)}><i>- {s}</i></a>)}
                    </div>

                    <input
                        type="text"
                        placeholder="Message Gravvy"
                        value={inputText}
                        onChange={handleInputChange}
                        onKeyUp={(e) => { if (e.keyCode === 13) handleButtonClick(); }}
                    />
                </div>)}

        </div>)
}