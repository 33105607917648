import React, { useState, useEffect } from 'react';
import { requestWithAuth, post } from '../fetch.js';
import { baseSelect, CheckboxSimple } from '../helpers.js';
import Loader from "./Loader.js";
import { AuthLoginModal } from '../auth.js';
import C from '../config.js'
import { useCSVDownloader, usePapaParse } from 'react-papaparse';
import GMCompare from './GMCompare.js'

const today = new Date().toLocaleDateString('en-CA');
const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA');

const granularityMapper = [
    { label: 'publisher', value: "accountId" },
    { label: 'app', value: 'appId' },
]

const findObjectsByProperty = (arr, property, searchString) => {
    return arr.filter(obj => obj[property] && obj[property].toString().toLowerCase().includes(searchString.toString().toLowerCase()));
};

const camelCaseToSpaces = (str) => {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

const DataTable = ({ data, onSort, onChangeShare, totals }) => {
    if (!data || data.length === 0) return <p>No data available</p>;

    const { CSVDownloader, Type } = useCSVDownloader();
    const { jsonToCSV } = usePapaParse();

    const columns = Object.keys(data[0]);

    const csvString = jsonToCSV(data);

    function naturalizeString(str) {
        let res = camelCaseToSpaces(str)
        res = res.replaceAll('_', ' ')
        return res;
    }

    function showTotal() {
        const total = sumByProperty(data, 'totalProfitGravite')
        if (typeof total == 'number') {
            return total.toFixed(2)
        } else {
            return 'Error'
        }
    }

    function renderShare(row, col) {
        if (row[col] == 0 || row[col].includes('_')) {
            return <input type="number" max={100} style={{ width: '60px' }} onChange={(e) => onChangeShare(row.id, e.target.value)} />
        } else {
            return row[col]
        }
    }

    return (<>
        <CSVDownloader data={csvString} style={{ margin: '1% 0', display: 'block' }} className="btn btn-dark" filename={'financeReport'} type={Type.CSV}>CSV</CSVDownloader>
        <div style={{ float: "right", textAlign: 'right' }}>
            <h4>Total Selected GM Profit: {showTotal()} $</h4>
            <p>*Total profit for Gravite Marketplace on Demand and Supply sides</p>
            {totals ? <ul style={{ listStyleType: 'none' }}>
                <li>*Total Supply profit: <b>{totals.supply.toFixed(2)} $</b></li>
                <li>*Total Demand profit: <b>{totals.demand.toFixed(2)} $</b></li>
            </ul> : null}
        </div>

        <table className="table tbl table-striped table-bordered">
            <thead className='header table-warning'>
                <tr className="bg-gray-200">
                    {columns.map((col) => (
                        <th key={col} style={{ cursor: 'pointer' }} className="border border-gray-400 px-4 py-2" onClick={() => onSort(col)}>{naturalizeString(col)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex} className="odd:bg-white even:bg-gray-100">
                        {columns.map((col) => (
                            <td key={col} className="border border-gray-400 px-4 py-2">{(col === 'share') ? renderShare(row, col) : row[col]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </>
    );
};

const groupByProperty = (arr, property) => {
    return arr.reduce((acc, obj) => {
        const key = obj[property];
        acc[key] = acc[key] || [];
        acc[key].push(obj);
        return acc;
    }, {});
};

const sumByProperty = (array, property) => {
    return array.reduce((acc, item) => {
        let value = item[property] || 0;
        if (typeof value === 'string' && !isNaN(value)) {
            value = Number(value);
        }
        return acc + value;
    }, 0);
};

const sortByProperty = (arr, key, ascending = false) => {
    arr.sort((a, b) => {
        if (a[key] === undefined || b[key] === undefined) return 0;

        let valueA = a[key];
        let valueB = b[key];

        // Cast numeric strings to numbers
        if (!isNaN(valueA)) valueA = Number(valueA);
        if (!isNaN(valueB)) valueB = Number(valueB);

        // Sort strings
        if (typeof valueA === "string" && typeof valueB === "string") {
            return ascending
                ? valueA.localeCompare(valueB)
                : valueB.localeCompare(valueA);
        }

        // Sort numbers
        if (typeof valueA === "number" && typeof valueB === "number") {
            return ascending ? valueA - valueB : valueB - valueA;
        }

        // Default to no sorting if types are mixed
        return 0;
    });
};

const GMOverview = (props) => {
    const [demandApiQuery, setDemandApiQuery] = useState(null);
    const [supplyApiQuery, setSupplyApiQuery] = useState(null);
    const [demandTotalsApiQuery, setDemandTotalsApiQuery] = useState(null);
    const [supplyTotalsApiQuery, setSupplyTotalsApiQuery] = useState(null);
    const [startDate, setStartDate] = useState(sevenDaysAgo);
    const [endDate, setEndDate] = useState(today);
    const [tableValues, setTableValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastFetched, setLastFetched] = useState(null);
    const [granularity, setGranularity] = useState('accountId');
    const [timeGran, setTimeGran] = useState('day');
    const [totals, setTotals] = useState(null);
    const [exchangeRateVal, setExchangeRateVal] = useState(null);
    const [shares, setShares] = useState(null);
    const [networkKeyMapper, setNetworkKeyMapper] = useState(null)
    const [fetchTotalProfit, setFetchTotalProfit] = useState(null)

    const token = props.user?.token;

    async function fetchInitialResources() {
        const resources = await requestWithAuth('reports/cacheResources?type=full');
        setShares(resources.shares);
        setNetworkKeyMapper(resources.networkKeyMapper)
        setExchangeRateVal(resources.exchangeRate)
    }

    async function fetchTotals() {
        // supply total
        const supplyTotals = await post(C.projects.core.api + '/reports/dashboardStats', { query: supplyTotalsApiQuery });
        // demand total
        const demandTotals = await requestWithAuth('reports/demandStats', 'POST', { reportUrl: demandTotalsApiQuery }, 'google');
        // set
        setTotals({
            supply: (supplyTotals.totals.revenues - supplyTotals.totals.netRevenues) * exchangeRateVal,
            demand: demandTotals.data[0].profit
        })
    }

    async function fetchStats() {
        setTableValues(null)
        setLoading(true);
        setLastFetched(new Date());
        // supply request
        const supplyResult = await post(C.projects.core.api + '/reports/dashboardStats', { query: supplyApiQuery });
        // demand request
        const demandResult = await requestWithAuth('reports/demandStats', 'POST', { reportUrl: demandApiQuery }, 'google');
        // Next
        postProcessData(demandResult, supplyResult)
        if (fetchTotalProfit) fetchTotals();
    }

    useEffect(() => {
        fetchInitialResources();
    }, [])

    useEffect(() => {
        createQuery();
    }, [token, startDate, endDate, granularity])

    function postProcessData(demand, supply) {
        // demand
        const demandData = demand.data;
        if (!networkKeyMapper.length) return;
        demandData.forEach(d => {
            let ex = findObjectsByProperty(networkKeyMapper, "networkKey", d.domain)
            if (ex && ex.length) {
                d.appId = ex[0].appId;
                d.appName = ex[0].appName;
                d.accountId = ex[0].accountId;
                d.accountName = ex[0].label.split('_')[0]
            } else {
                console.log('Err', d.domain)
            }
        })

        // supply
        const supplyData = supply.reply.data.series;

        const newValues = []
        // demand
        // group by account
        const groupedByAcc = groupByProperty(demandData, granularity);

        Object.keys(groupedByAcc).forEach(g => {
            const row = {}
            if (granularity === 'appId') {
                row.name = groupedByAcc[g][0].appName;
                row.id = groupedByAcc[g][0].appId
            }
            if (granularity === 'accountId') {
                row.name = groupedByAcc[g][0].accountName
                row.id = groupedByAcc[g][0].accountId
            }
            // sum all dsp_spend
            row.dsp_spend = sumByProperty(groupedByAcc[g], 'dsp_spend').toFixed(2)
            // sum all ssp_revenue
            row.ssp_revenue = sumByProperty(groupedByAcc[g], 'ssp_revenue').toFixed(2)
            // sum all profit
            row.demand_profit = sumByProperty(groupedByAcc[g], 'profit').toFixed(2)
            newValues.push(row);
        })

        supplyData.forEach(sd => {
            // look for Id
            const indexCombined = newValues.findIndex(nv => nv.id === sd.id);
            if (indexCombined > -1) {
                newValues[indexCombined].supply_revenues = (sd.total[0] * exchangeRateVal).toFixed(2)
                newValues[indexCombined].supply_net_revenues = (sd.total[1] * exchangeRateVal).toFixed(2)
            }
        })

        if (granularity === 'accountId') {
            newValues.forEach(nv => {
                const indexCombined = shares.accounts.findIndex(sacc => nv.id === sacc.accountId);
                nv.share = (indexCombined > -1) ? shares.accounts[indexCombined].share : 0
            })
        }


        if (granularity === 'appId') {
            newValues.forEach(nv => {
                const indexCombined = shares.apps.findIndex(sacc => nv.id === sacc.appId);
                nv.share = (indexCombined > -1) ? shares.accounts[indexCombined].share : 0
            })
        }

        // total
        newValues.forEach(nv => {
            nv.supplyProfit = (nv.supply_revenues - nv.supply_net_revenues).toFixed(2);
            nv.totalProfitGravite = (parseFloat(nv.demand_profit) + parseFloat(nv.supplyProfit)).toFixed(2);
        })

        setTableValues(newValues);
        setLoading(false);
    }

    const capitalize = (str, lower = false) =>
        (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    ;

    function createQuery() {
        // demand
        let demandQuery = token + "/adx-report?attribute[]=domain&metric[]=ssp_revenue&metric[]=dsp_spend&metric[]=profit";
        demandQuery += '&from=' + startDate + '&to=' + endDate;
        demandQuery += '&day_group=total' // + timeGran.toLowerCase();
        demandQuery += '&limit=10000'
        setDemandApiQuery(demandQuery);

        // demand Totals
        let demandTotalsQuery = token + "/adx-report?metric[]=profit";
        demandTotalsQuery += '&from=' + startDate + '&to=' + endDate;
        demandTotalsQuery += '&day_group=total' // + timeGran.toLowerCase();
        setDemandTotalsApiQuery(demandTotalsQuery);

        // supply
        let supplyQuery = "/statistics/v2/revenues?";
        supplyQuery += "selector=" + granularity + ",event" + capitalize(timeGran);
        supplyQuery += "&dateAsColumns=true&networkId=" + C.graviteRtbId + ',' + C.googleBiddingId;
        supplyQuery += "&from=" + startDate + "&to=" + endDate + "&revenues=true";
        setSupplyApiQuery(supplyQuery)

        // supply totals
        let supplyTotalsQuery = '/dashboard/revenues?'
        supplyTotalsQuery += "from=" + startDate + "&to=" + endDate + "&type=revenues";
        setSupplyTotalsApiQuery(supplyTotalsQuery)
    }

    function sorting(propSelected) {
        const toSort = [...tableValues]
        setLoading(true);
        sortByProperty(toSort, propSelected);
        setTableValues(toSort);
        setLoading(false);
    }

    function changeShare(id, newValue) {
        const index = tableValues.findIndex(nv => nv.id === id);
        if (index > -1) {
            const newData = [...tableValues];
            newData[index].share = '_' + newValue;
            const netRev = (newData[index].supply_revenues * parseInt(newValue) / 100).toFixed(2);
            newData[index].supplyProfit = netRev;
            newData[index].totalProfitGravite = (parseFloat(newData[index].demand_profit) + parseFloat(netRev)).toFixed(2);
            setTableValues(newData);
        }
    }

    return (
        <div style={{ width: '90%', margin: 'auto' }}>
            <div>
                <div className="row align-items-start" style={{ display: 'inline-flex', margin: '2%', width: '94%' }}>
                    <div className="col-9">
                        {baseSelect({
                            title: 'Granularity',
                            defaultValue: granularityMapper[0],
                            options: granularityMapper,
                            onChange: (e) => setGranularity(e.value)
                        })}

                        <CheckboxSimple
                            label={'Fetch Demand & supply totals (Not only GM related)'}
                            onChange={() => {
                                setFetchTotalProfit(!fetchTotalProfit)
                            }}
                        />
                    </div>

                    <div className='col-3'>
                        <h5 htmlFor="start">From-To</h5>
                        <input style={{ float: 'left' }} type="date" id="start" name="trip-start" value={startDate} min="2018-01-01" max={today} onChange={(e) => setStartDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                        <input type="date" id="end" name="trip-start" value={endDate} min="2018-01-01" max={today} onChange={(e) => setEndDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                    </div>
                </div>
                <br />
                <button type="button" style={{ marginBottom: '50px', width: '100%' }} className="btn btn-primary" onClick={fetchStats}>Run</button>
                <br />
                {lastFetched ? <div style={{ float: 'right' }}>
                    <button type="button" onClick={() => fetchStats()} className="btn btn-link"><i className="bi bi-arrow-clockwise" /> </button>
                    <span >Last sync: {lastFetched.toLocaleDateString()}: {lastFetched.toLocaleTimeString()}</span>
                </div> : null}
                {tableValues ? (<div>

                    <h5>Gravite Marketplace Demand vs Supply</h5>

                    <br /><i>Gravite API does not offer profit as metric, this is calculated substracting NetRevenues from Revenues </i>
                    <br /><i>In oder to get data from Gravite API, we request from network Gravite Marketplace (76) & Google Bidding (77) </i>
                    <br /><i>Some accounts have a dynamic share so here will be shown as Custom input, when initial supply profit is not zero, it is because some apps & NetworksKeys of that Publisher have predefined shares </i>
                    <br /><i>Demand is in $ whereas supply is gathered in EUR and later converted to $ with an <b>Exchange Rate:  {exchangeRateVal} </b></i>

                    <DataTable data={tableValues} onChangeShare={(id, newValue) => changeShare(id, newValue)} onSort={(property) => sorting(property)} totals={totals} />
                </div>) : null}
                {loading ? <Loader /> : null}

            </div>
        </div>
    )
};

const GmPages = ({ user, onLogout }) => {
    const [selected, setSelected] = useState(0)
    return (
        <div className="container-fluid">

            <ul className="nav nav-tabs">
                <li className="nav-item" onClick={() => setSelected(0)}>
                    <a className={selected === 0 ? 'nav-link active' : 'nav-link'} aria-current="page" href="#">GM Overview</a>
                </li>
                <li className="nav-item" onClick={() => setSelected(1)}>
                    <a className={selected === 1 ? 'nav-link active' : 'nav-link'} aria-current="page" href="#">GM comparison</a>
                </li>
            </ul>
            {selected === 0 ? <GMOverview user={user} onLogout={onLogout}/> : null}
            {selected === 1 ? <GMCompare user={user} onLogout={onLogout}/> : null}
        </div>
    )
}

const Wrapper = () => {
    return (<AuthLoginModal target='demand' style='rtb' open={true} fallback={(user, onLogout) => <GmPages user={user} onLogout={onLogout} />} />);
}

export default Wrapper